@import '__importable.scss';
.filtersButton {
	padding-inline: 0.8rem;
	width: 100%;
	min-width: 25%;

	@media screen and (min-width: $media-md-max) {
		padding-inline: 1.6rem;
		width: auto;
	}

	@media screen and (min-width: $media-xl) {
		padding-inline: 3.2rem;
		padding-bottom: 0;
	}

	@media screen and (min-width: $media-xxl) {
		padding-inline: 6.4rem;
	}

	&BfTops {
		padding-inline: 0;
		min-width: 17rem;
		padding-bottom: 0;

		@media screen and (min-width: $media-md-max) {
			padding-inline: 0;
			width: auto;
		}
	}
	&IsFilterOutline {
		padding-right: 2.4rem;
		button {
			font-size: 1.6rem !important;
			padding: 0.95rem 0.8rem !important;
		}
	}
}
